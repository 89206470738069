import React from 'react';
import { extractInlineStyles } from '../utils';
import styles from '../RichText.module.scss';
import { RichTextNode } from '../types';

const RichTextOrderedList = ({ children, value }: RichTextNode) => (
  <ol className={styles.richTextList} style={extractInlineStyles(value)}>
    {children}
  </ol>
);

RichTextOrderedList.dispayName = 'RichTextOrderedList';

export default RichTextOrderedList;
