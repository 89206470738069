import React from 'react';
import { extractInlineStyles } from '../utils';
import { RichTextNode } from '../types';

const RichTextItalics = ({ children, value }: RichTextNode) => (
  <i style={extractInlineStyles(value)}>{children}</i>
);

RichTextItalics.dispayName = 'RichTextItalics';

export default RichTextItalics;
