import React from 'react';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextH2 = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ baseClass: 'richTextH2', value });
  return (
    <h2 className={className} style={style}>
      {children}
    </h2>
  );
};

RichTextH2.dispayName = 'RichTextH2';

export default RichTextH2;
