import React from 'react';
import { extractInlineStyles, extractChildElementInlineStyles } from '../utils';
import { RichTextNode } from '../types';

const RichTextUnderline = ({ children, value }: RichTextNode) => {
  const inlineStyles = {
    ...extractInlineStyles(value),
    ...extractChildElementInlineStyles(value, 'span'),
  };

  return <u style={inlineStyles}>{children}</u>;
};

RichTextUnderline.dispayName = 'RichTextUnderline';

export default RichTextUnderline;
