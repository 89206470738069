import React from 'react';
import { extractInlineStyles, extractChildElementInlineStyles } from '../utils';
import { RichTextNode } from '../types';

const RichTextListItem = ({ children, value }: RichTextNode) => {
  const inlineStyles = {
    ...extractInlineStyles(value),
    ...extractChildElementInlineStyles(value, 'span'),
  };

  return <li style={inlineStyles}>{children}</li>;
};

RichTextListItem.dispayName = 'RichTextListItem';

export default RichTextListItem;
