import PropTypes from 'prop-types';

export default () =>
  PropTypes.shape({
    promoName: PropTypes.string,
    promoType: PropTypes.string,
    promoPeriod: PropTypes.string,
    bannerPurpose: PropTypes.string,
    buyingOfficeAndGroup: PropTypes.string,
  });
