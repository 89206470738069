import React from 'react';
import { Link } from 'react-router-dom';

import { isInternal } from 'utils/is-internal';

import styles from 'cms-components/RichText/RichText.module.scss';
import { extractInlineStyles, extractChildElementInlineStyles } from '../utils';
import { RichTextAnchorProps } from '../types';

function handleCmsLocation(href: string) {
  const base = '/content/waitrosegroceriescms/en';
  const isCmsLocation = href.includes(base);

  if (isCmsLocation) {
    const [, cmsLocation] = href.split(base);
    return `/ecom${cmsLocation.replace('.html', '')}`;
  }
  return href;
}

const RichTextAnchor = ({ value, children, onRichTextClick }: RichTextAnchorProps) => {
  const { attributes } = value;
  const { href, target, title = '' } = attributes || {};

  if (!href) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const inlineStyles = {
    ...extractInlineStyles({ attributes }),
    ...extractChildElementInlineStyles(value, 'span'),
  };

  const url = handleCmsLocation(href);

  const handleOnClick = () => {
    const newWindow = target === '_blank';
    onRichTextClick({ newWindow, title, linkUrl: url });
  };

  return isInternal(url) ? (
    <Link
      className={styles.anchor}
      onClick={handleOnClick}
      style={inlineStyles}
      target={target}
      title={title}
      to={url}
    >
      {children}
    </Link>
  ) : (
    <a
      className={styles.anchor}
      href={url}
      onClick={handleOnClick}
      style={inlineStyles}
      target={target}
      title={title}
    >
      {children}
    </a>
  );
};

RichTextAnchor.displayName = 'RichTextAnchor';

export default RichTextAnchor;
