import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';

const getLinkTarget = (newWindow: boolean) => ({ target: newWindow ? '_blank' : '_self' });

interface PromoClickEventData {
  bannerPurpose?: string;
  buyingOfficeAndGroup?: string;
  creative?: string;
  id?: string;
  name?: string;
  position?: string;
  promoPeriod?: string;
  promoType?: string;
  assetModuleType?: string;
  enhancedEcommerceInternalPromotion?: string;
  newWindow?: boolean;
}
const usePromoClickEvent = () => {
  const { trackEvent } = useAnalytics();

  const promoClick = ({
    bannerPurpose,
    buyingOfficeAndGroup,
    creative,
    id,
    name,
    position,
    promoPeriod,
    promoType,
    assetModuleType = 'Unassigned',
    enhancedEcommerceInternalPromotion = 'Unassigned',
    newWindow,
    ...props
  }: PromoClickEventData = {}) => {
    trackEvent({
      event: 'promo_click',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              assetModuleType,
              ...(!!bannerPurpose && { banner_purpose: bannerPurpose }),
              ...(!!buyingOfficeAndGroup && { buying_office_and_group: buyingOfficeAndGroup }),
              creative,
              enhancedEcommerceInternalPromotion,
              id,
              name,
              position,
              ...(!!promoPeriod && { promo_period: promoPeriod }),
              ...(!!promoType && { promo_type: promoType }),
              ...(newWindow !== undefined && getLinkTarget(newWindow)),
              ...props,
            },
          ],
        },
      },
    });
  };

  return promoClick;
};

export default usePromoClickEvent;
