import React from 'react';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextH4 = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ baseClass: 'richTextH3', value });
  return (
    <h4 className={className} style={style}>
      {children}
    </h4>
  );
};

RichTextH4.dispayName = 'RichTextH4';

export default RichTextH4;
