import classNames from 'classnames';

import styles from './RichText.module.scss';

/*

Back-End is implemented based on a "Portable Text"
https://www.jlpit.com/wiki/pages/viewpage.action?spaceKey=WSU&title=Translating+AEM+Rich+text+into+JSON
https://github.com/portabletext/portabletext

Instated of witting our very own serializer, we are using a plugin which already support most of the features.
https://github.com/sanity-io/block-content-to-react

Function below modifies what we get from the BE into what we can use in the FE.

*/

const camelCase = str =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

const upperFirst = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : str);

export const camelCaseKeys = (attributes = {}) =>
  Object.entries(attributes).reduce(
    (updatedKeyObject, [key, value]) => ({
      ...updatedKeyObject,
      [camelCase(key)]: value,
    }),
    {},
  );

export const extractInlineStyles = ({ attributes = {} }) => {
  const { color, textAlign, marginLeft } = camelCaseKeys(attributes);

  return { color, textAlign, marginLeft };
};

/**
  Get child element inline styles from a given element & index
  @param {object} node - Node element representation from AEM.
  @param {string} elementStyle - Name of the target element e.g 'span'
  @param {number} index - Index for the elementStyle
  @returns {object} - Returns an object containing inline styles
  */
export const extractChildElementInlineStyles = (node, elementStyle, index = 0) => {
  if (!node) return {};
  if (node.children[index]?.style !== elementStyle) return {};

  return extractInlineStyles(node.children[index]);
};

export const getCustomFontStyle = ({ fontSize, fontWeight, fontFamily }) => {
  const hasCustomStyling = !!(fontSize || fontWeight || fontFamily);
  if (hasCustomStyling) {
    return {
      fontFamilyValue: fontFamily,
      fontSizeValue: fontSize,
      fontWeightValue: fontWeight,
      hasFontFamily: !!fontFamily,
      hasFontSize: !!fontSize,
      hasFontWeight: !!fontWeight,
      useBaseClass: false,
    };
  }

  // no custom styling
  return {
    hasFontFamily: false,
    hasFontSize: false,
    hasFontWeight: false,
    useBaseClass: true,
  };
};

export const buildElementStyling = ({ baseClass = '', value = {} } = {}) => {
  const { attributes = {}, isLastItem = false } = value;
  const keys = camelCaseKeys(attributes);

  const { fontSize, fontWeight, font: fontFamily } = keys;
  const inlineStyles = extractInlineStyles({ attributes });

  const {
    fontFamilyValue,
    fontSizeValue,
    fontWeightValue,
    hasFontSize,
    hasFontFamily,
    hasFontWeight,
    useBaseClass,
  } = getCustomFontStyle({
    fontFamily,
    fontSize,
    fontWeight,
  });

  return {
    className: classNames({
      [styles[baseClass]]: useBaseClass && baseClass,
      [styles[`fontSize${fontSizeValue && fontSizeValue.replace('.0px', 'px')}`]]: hasFontSize,
      [styles[`fontWeight${upperFirst(fontWeightValue)}`]]: hasFontWeight,
      [styles[fontFamilyValue]]: hasFontFamily,
      [styles.noMargin]: isLastItem,
    }),
    style: inlineStyles,
  };
};

export const getNewLineCharacterCount = str => {
  const re = /\r\n|\r|\n/g;
  return ((str && str.match(re)) || []).length;
};

const getLastItemVisually = ({ richTextBlocks, index }) => {
  // handle redundant br at the end of the blocks
  // we want to figure out which is the last item - ignore the spans with empty lines
  const numberOfItems = richTextBlocks.length;
  const isLastItem = index === numberOfItems - 1;
  const isBeforeLastItem = index === numberOfItems - 2;

  const lastItem = richTextBlocks?.[numberOfItems - 1];
  // eslint-disable-next-line no-underscore-dangle
  const lastItemIsSpan = (lastItem?.type || lastItem?._type) === 'span';
  const lastItemIsEmptyLine = getNewLineCharacterCount(lastItem?.text) === 1;
  const lastItemIsSpanWithEmptyLine = lastItemIsSpan && lastItemIsEmptyLine;

  return (
    (isBeforeLastItem && lastItemIsSpanWithEmptyLine) ||
    (isLastItem && !lastItemIsSpanWithEmptyLine)
  );
};

export const richTextSerializer = (richTextBlocks = []) => {
  try {
    // add 'marks' property to text, line breaks
    const blocks = richTextBlocks.map((block, index) => {
      const updatedBlock = block;
      updatedBlock.isLastItem = getLastItemVisually({ richTextBlocks, index });

      const isSpanText = updatedBlock.type === 'span' && !!updatedBlock.text;
      return {
        ...updatedBlock,
        ...(isSpanText && { mark: 'span' }),
        _key: `block-${index}`,
      };
    });
    // replace all property names from 'type' into '_type'
    // fastest way to do this is replacing text in a string-ified json
    const blocksAsString = JSON.stringify(blocks);
    const blocksWithReplacedType = blocksAsString.replace(/"type"/g, '"_type"');
    return JSON.parse(blocksWithReplacedType);
  } catch (error) {
    return [];
  }
};
