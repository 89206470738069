import PropTypes from 'prop-types';

export default () =>
  PropTypes.shape({
    deviceBreakpoints: PropTypes.arrayOf(
      PropTypes.shape({
        layout: PropTypes.string,
        name: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        width: PropTypes.string,
        offset: PropTypes.string,
      }),
    ),
  });
