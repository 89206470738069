import React from 'react';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextSpan = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ value });
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
};

RichTextSpan.dispayName = 'RichTextSpan';

export default RichTextSpan;
